//import logo from './logo.svg';

import React from 'react';
import Candilist from './Candilist';
import Candiprofile from './Candiprofile';

//import axios from "axios";

//import { useLocation } from 'react-router-dom';


const Candidash=()=> {
  const textFromStorage = localStorage.getItem('myprofile');
	console.log("searchdata:"+textFromStorage);

 


  return (
    <div className='container'>
<Candiprofile/>

 <Candilist/>
    </div>
  );
}
export default Candidash;