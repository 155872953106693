import React from "react";
//import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
// Import components
import Home from "./component/Homepage";
import About from "./component/About";
import Blog from "./component/Blog";
import './component/style.css';
import Headertop from "./component/Headertop";
import Loginform from "./component/Registerdetails";
import Candidash from "./component/Candidash";
import Join from "./component/Joindonate";
//import Login from "./component/Login";
//import Logout from "./component/Logout";
import { useState } from "react";
import { selectSignedIn } from "./features/userslicer";
const App = (probs) => {
  
 // const isSignedIn = useSelector(selectSignedIn);
  function Knob(props){
    let [panLevel, setPanLevel] = useState(50);
    const isSignedIn = useSelector(selectSignedIn);
    setPanLevel(isSignedIn);
    //console.log(setPanLevel);
    return panLevel;
  }
return(
 
  <Router>
    
    <Headertop isSignedIn={Knob}/>
    
   
    <Routes>
      {/* Exact match to avoid 
          overriding other routes */}
      <Route path='/' element={<Home />}>
        
      </Route>
      <Route path='/about' element={<About />}>
        
      </Route>
      <Route path='/blog' element={<Blog />}>
     
      </Route>
      {Knob ? (
      <Route path='/Registration' element={<Loginform />}>
      
     </Route>): (<Route path='/' element={<Home />}>
        
        </Route>)
}
    
     <Route path='/Candidash' element={<Candidash />}>
     
     </Route>
     <Route path='/join' element={<Join />}>
     
     </Route>
    </Routes>
  </Router>
);
        }
export default App;
//const rootElement = document.getElementById("root");
//ReactDOM.render(<App />, rootElement);