//import logo from './logo.svg';
//import Headertop from '../component/Headertop';
import React from 'react';
import { useEffect, useState } from "react";
//import { Link, useNavigate } from "react-router-dom";
const Candilist=()=> {
    const [empdata, empdatachange] = useState(null);
   // const navigate = useNavigate();

    useEffect(() => {
        fetch("http://localhost:3001/candilist").then((res) => {
            return res.json();
        }).then((resp) => {
            empdatachange(resp);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [])

  return (
    <div className='container'>

          
           <table className="table">
            <thead className="table-dark">
            
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">State</th>
      <th scope="col">Edit</th>
      <th scope="col">Delete</th>
      <th scope="col">View</th>
    </tr>
  </thead>
  <tbody>
  {empdata &&
        empdata.map(item => (
               <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.canname}</td>
                                        <td>{item.canemail}</td>
                                        <td>{item.yrstate}</td>
                                        <td><button onClick={() => { }} className="btn btn-success">Edit</button> </td>
                                          <td>  <button onClick={() => { }} className="btn btn-danger">Remove</button> </td>
                                        <td> <button onClick={() => {  }} className="btn btn-primary">Details</button> </td>
                                    </tr>
                                ))
                            }
  </tbody>
  </table>
    </div>
  );
}
export default Candilist;