import React from "react";

//Blog Component
function Blog() {
  return (
    <div className="app">
      <h1>Blog Page Body</h1>
    </div>
  );
}

export default Blog;