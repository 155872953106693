import React from "react";

//Homepage Component
function Joindonate() {
  return (
    <div className="app">
    
    </div>
  );
}

export default Joindonate;