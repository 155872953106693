import React, { useState  } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import './Loginformcss.css';
import Indiastate from './allstateanddist.json';
import Allzonediv from './allzonediv.json';
import { Multiselect } from 'multiselect-react-dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

import {
  //selectSignedIn,
  selectUserNameData,
  selectUserEmailData
 
} from "../features/userslicer"; 


const Loginform = (e) => {
  
  //const isSignedIn = useSelector(selectSignedIn);
   //console.log("isSignedIn--"+isSignedIn);
    const userNameData = useSelector(selectUserNameData);
    const userEmailData = useSelector(selectUserEmailData);

   console.log("username--"+userNameData);
   console.log("useremail--"+userEmailData);
  const navigate = useNavigate();
//console.log("welcome data"+data.email);

  //const[countryid, setCountryid]=useState('');
  const[contrystate, setcontrystate]=useState([]);
 // const[stateid, setStateid]= useState('');
 const [getdivs, setGetdivis]=useState([]);  
 const [getwantzone, setGetwantzone]=useState([]);
 const [yrstate, yrstatechge] =useState("");
 const [jobselzone, jobselzonechge] =useState("");
 const [wantzone, wantzonechge] =useState("");
 
  const handlestate =(e)=>{
    
    const getstatename=e.target.value;
    const getcitydata = Indiastate.find(indiastates=>indiastates.statename===getstatename).districts;
    yrstatechge(getstatename);
    setcontrystate(getcitydata);

  }
  const handleselectedzone=(e)=>{
    const getselectedzone=e.target.value;
    const getdivision = Allzonediv.find(zoneshname=>zoneshname.zoneshortname===getselectedzone).division;
    jobselzonechge(getselectedzone);
    setGetdivis(getdivision);
  }
  const handlewantedzone=(e)=>{
    const getwantedzone=e.target.value;
    
    const getwantzonedetails = Allzonediv.find(zoneshname=>zoneshname.zoneshortname===getwantedzone).division;
    wantzonechge(getwantedzone);
    setGetwantzone(getwantzonedetails);

  }
 // const [candid, candidchange] = useState("");
  

 //const [dbm, setDbm] = useState([]);

 const checkEmail = (labledata,formData) => {
  
/*
const userd = labledata.find(function (element) {
  if(element === formData)
  {
    //alert("eruku");

  }else{// alert("ella"); 
  }
  
});
  */
  
 };
 //const [canname, cannamechange] = useState("");
 //const [canemail, canemailchange] = useState("");
 const [gender, genderchg] = useState("");
 const [category, categorychge] = useState("");
 const [dob, dobchge] =useState("");
 const [Contno, contnochge] =useState("");
 
 const [yrcity, yrcitychge] =useState("");

 
 const [jobseldivs, jobseldivschge] =useState("");
 const [joblevel, joblevelchge] =useState("");
 const [wrknature, wrknaturechge] =useState("");
 const [dofjoin, dofjoinchge] =useState("");
 const [medicalstd, medicalstdchge] =useState("");
 
 //const [wantdivs, wantdivschge] =useState("");
 const [setyrpass, setyrpasschge] =useState("");
 const [setretpypass, setretpypasschge] =useState("");
 
  const handlesubmitdata=(e,da)=>{
    e.preventDefault();
    let canname=userNameData;
    let canemail=userEmailData;
    const uniqid= `${dob}-${uuidv4()}`;

 let regobj={
  uniqid,canname,canemail,category,gender,dob,Contno,yrstate,yrcity,
  jobselzone,jobseldivs,joblevel,wrknature,dofjoin,medicalstd,wantzone,getwantzone,setyrpass,setretpypass
};
let candiregis={
  uniqid,canname,canemail,setyrpass,setretpypass
};

     axios
    .get("http://localhost:3001/candilist")
       .then((res) => checkEmail(res.data,canemail));
 
      
        fetch("http://localhost:3001/candilist", {
          method: "POST",
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(regobj)
      }).then((res) => {
              fetch("http://localhost:3002/candidashdetails", {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(candiregis)
            }).then((res) => {
            
              toast.success('Registered successfully.');
              setTimeout(() => {
                navigate('/Candidash');
              }, 5000);
                
            }).catch((err) => {
                toast.error('Registered Failed :' + err.message);
            });

         
         
          
      }).catch((err) => {
          toast.error('Failed :' + err.message);
      });
    
  }

 
  
   
/*
 const [datalon, setDatalon] = useState([]);
  const fetchInfo = () => {
    const url = "http://localhost:3001/user";
    return fetch(url)
      .then((res) => {
      console.log("mathdataa--"+res.json())
     // const filtered = res.items.filter(item => item.canemail === "sudhakarmca20@gmail.com")
     // console.log(filtered);
      })
      .then((d) => setDatalon(d))
  }


  useEffect(() => {
    fetchInfo();
  }, []);
*/
    return(
    
<div className="container"> <br/><br/>


      <ToastContainer />
<form className="row g-3" id="regdata" onSubmit={handlesubmitdata}>
    <div className="col-md-4">
    <label htmlFor="Name" className="form-label">Name</label>
    <input type="text" className="form-control" id="inputName" value={userNameData} required />
  </div>
 
  <div className="col-md-4">
 
    <label htmlFor="inputEmail4" className="form-label">Email</label>
  
    <input type="email" className="form-control" id="inputEmail4" value={userEmailData} required/>
   
  
  </div>
  
  <div className="col-md-4">
  <div className="form-group">
                <label htmlFor="gender">Gender:</label>
                <select className="form-select" id="gender" onChange={e=>genderchg(e.target.value)} >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
            </div>
    </div>
    <div className="col-md-4">
  <div className="form-group">
                <label htmlFor="category">Category:</label>
                <select className="form-select" id="category" onChange={e=>categorychge(e.target.value)}>
                    <option value="">Select category</option>
                    <option value="ur">UR</option>
                    <option value="obc">OBC</option>
                    <option value="sc">SC</option>
                    <option value="st">ST</option>
                    <option value="ews">EWS</option>
                </select>
            </div>
    </div>
    
    <div className="col-md-4">
  <div className="form-group">
                <label htmlFor="dob">Date of Birth:</label>
                <input type="date" className="form-control" id="dob" onChange={e=>dobchge(e.target.value)} />
            </div>
    </div>
    <div className="col-md-4">
      <div className="form-group">
    <label htmlFor="inputcontactno" className="form-label">Contact No</label>
    <input type="number" className="form-control" id="inputcontactno" onChange={e=>contnochge(e.target.value)} />
    
  </div>
  </div>
   <div className="col-md-4">
    <label htmlFor="inputState" className="form-label">State</label>
    <select id="inputState" className="form-select" onChange={(e)=>handlestate(e)} >
      <option selected>Choose...</option>
      
      {
        Indiastate.map((result)=>{
        return <option key={result.statename}>{result.statename}</option>
        })
      }
      
    </select>

    


  </div>
   <div className="col-md-4">
    <label htmlFor="inputCity" className="form-label">City</label>
    <select id="inputCity" className="form-select" onChange={e=>yrcitychge(e.target.value)}>
      <option selected>Choose...</option>
      
      {
                          contrystate.map((getstate,index)=>(
                <option value={getstate} key={index}>{getstate}</option>
                
                          ))
                        }      
             
      
    </select>
  </div>
    <div className="col-md-4">
   
  </div>
   <div className="col-md-12">
    <hr /> 
  </div>
  <div className="col-md-4">
    <div className="form-group">
                <label htmlFor="sltzone">Selected Zone:</label>
                <select className="form-select" id="sltzone1" onChange={(e)=>handleselectedzone(e)} >
                    <option value="">Select Zone</option>
                    
                      {
                        Allzonediv.map((result)=>{
                        return <option value={result.zoneshortname}>{result.zoneshortname}{" "+result.zoneheadquter}</option>
                        })
                      }
                   </select>
            </div>
    </div>
    <div className="col-md-4">
    <div className="form-group">
                <label htmlFor="sltzone">Selected Division:</label>
                <select className="form-select" id="sltzone2" onChange={e=>jobseldivschge(e.target.value)} >
                {
                          getdivs.map((getdivis,index)=>(
                <option value={getdivis} key={index}>{getdivis}</option>
                
                          ))
                        } 
                    
                </select>
            </div>
    </div>
     <div className="col-md-4">
    <label htmlFor="inputjoblevel" className="form-label">Job Level</label>
    <select id="inputjoblevel" className="form-select" onChange={e=>joblevelchge(e.target.value)} >
      <option selected>Choose...</option>
      <option>Level1</option>
    </select>
  </div>
   <div className="col-md-4">
    <label htmlFor="inputwrok" className="form-label">Work Nature</label>
    <select id="inputwrok" className="form-select" onChange={e=>wrknaturechge(e.target.value)} >
      <option selected>Choose...</option>
      <option>mach</option>
    </select>
  </div>
   <div className="col-md-4">
  <div className="form-group">
                <label htmlFor="dojin">Date of Joining:</label>
                <input type="date" className="form-control" id="dojin"  onChange={e=>dofjoinchge(e.target.value)}/>
            </div>
    </div>
   <div className="col-md-4">
  <div className="form-group">
                <label htmlFor="medical">Medical:</label>
                <select className="form-select" id="medical" onChange={e=>medicalstdchge(e.target.value)}>
                    <option value="">Select Medical</option>
                    <option value="a1">A1</option>
                    <option value="a2">A2</option>
                    
                </select>
            </div>
    </div>
   <div className="col-md-4">

    <div className="form-group">
                <label htmlFor="wtdzone">Wanted Zone:</label>
                <select className="form-select" id="wtdzone" onChange={(e)=>handlewantedzone(e)} >
                    <option value="">Select Zone</option>
                   
                    {
                        Allzonediv.map((result)=>{
                        return <option value={result.zoneshortname}>{result.zoneshortname}{" "+result.zoneheadquter}</option>
                        })
                      }
                    
                </select>
            </div>
    </div>
    <div className="col-md-4">

<div className="form-group">
            <label htmlFor="wtdzone">Wanted Division:</label>
           
            <Multiselect options={getwantzone} displayValue={getwantzone}
            isObject={false}
            onSelect={(event) => {
              console.log(event);
            }}
            
            />
           
        </div>
</div>
  <div className="col-md-4">
    <label htmlFor="inputPassword4" className="form-label">Password</label>
    <input type="password" className="form-control" id="inputPassword4"  onChange={e=>setyrpasschge(e.target.value)}/>
  </div>
  <div className="col-md-4">
    <label htmlFor="inputPassword5" className="form-label">Retype Password</label>
    <input type="password" className="form-control" id="inputPassword5" onChange={e=>setretpypasschge(e.target.value)} />
  </div>

  <div className="col-12">
    <div className="form-check">
      <input className="form-check-input" type="checkbox" id="gridCheck" />
      <label htmlFor="gridCheck" className="form-check-label" for>
        Check me out
      </label>
    </div>
  </div>
  <div className="col-12">
    <button type="submit" className="btn btn-primary">Register</button>
  </div>
</form>
   
        </div>
   


    );
};
export default Loginform;