import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
//import { googleLogout } from '@react-oauth/google';
//import Logout from "./Logout";
//import Homepage from "./Homepage";
//import { useState, useEffect } from "react";
//import { Avatar } from "@material-ui/core";
//import { GoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSignedIn,
  selectUserNameData,
  selectUserEmailData,
  setSignedIn,
  setUserName,
  setUserEmail
} from "../features/userslicer"; 
const Headertop=(probs)=>{
  
  const localdataname = localStorage.getItem('myprofilename');
  const localdataemail = localStorage.getItem('myprofileemail');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSignedIn = useSelector(selectSignedIn);
  console.log("headerdata--"+isSignedIn);
 // console.log("userifo--"+isSignedIn);
  const userName = useSelector(selectUserNameData);
  const userEmail = useSelector(selectUserEmailData);

  if(!userEmail){
    dispatch(setSignedIn(true));
    dispatch(setUserName(localdataname));
    dispatch(setUserEmail(localdataemail));
  }
  
 
 

  const logout = (response) => {
    dispatch(setSignedIn(false));
    dispatch(setUserName(null));
    dispatch(setUserEmail(null));
    localStorage.removeItem('myprofilename');
    localStorage.removeItem('myprofileemail');
    navigate('/');
  };



        return(
          
       
    
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
       
          <a className="navbar-brand" href="/#">Logo</a>
          <div />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="mynavbar">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
              <Link to="/" className="nav-link">Home</Link>
              </li>
              <li className="nav-item">
              <Link to="/Registration" className="nav-link">Registration</Link>
             
              </li>
              <li className="nav-item">
              <Link to="/Candidash" className="nav-link">Your Dashboard</Link>
             
              </li>
              <li className="nav-item">
              <Link to="/join" className="nav-link">Join</Link>
             
              </li>
              <li className="nav-item">
              <Link to="/about" className="nav-link">About</Link>
             
              </li>
             
              <li className="nav-item">
              <Link to="/blog" className="nav-link">Blog</Link>
               
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#">Contact</a>
               
              </li>
            </ul>
            <form className="d-flex">
              <input className="form-control me-2" type="text" placeholder="Search" />
              <button className="btn btn-primary" type="button">Search</button>
            </form>

           

            {isSignedIn ? (
        <div className="navbar__user__data">
          
          
          <h6 className="signedIn">{userName}</h6>
           <button onClickCapture={logout}>Logout</button>
        </div>
      ) : (
        <h6 className="notSignedIn">User not available 😞</h6>
      )}
           
                        
          </div>
       
        </nav>
     
      
    );
};

export default Headertop;