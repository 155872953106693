import React from "react";
//import { useState } from "react";
//import { GoogleLogin } from "react-google-login";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
//import { Link } from "react-router-dom";
//import Loginform from "./Registerdetails";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
//  selectSignedIn,
setSignedIn,
setUserName,
setUserEmail,
} from "../features/userslicer";


const Login=(props)=>{
  
  const clientId="497851142339-djeovmpufkodjl4fjcq3dpd66lrb0sni.apps.googleusercontent.com";
  //const isSignedIn = useSelector(selectSignedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();




    return(
      <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin 
        onSuccess={credentialResponse => {
          const details= jwt_decode(credentialResponse.credential);
        //  console.log("jwtdetails:"+details);
          alert(details.name);
          //console.log("getfromgoogle"+parseInt.toString(details));
          //const details= jwt_decode(response.credential);
          localStorage.setItem('myprofilename', details.name);
          localStorage.setItem('myprofileemail', details.email);
          dispatch(setSignedIn(true));
          dispatch(setUserName(details.name));
          dispatch(setUserEmail(details.email));
          
          
                  navigate('/Registration');
          
        
        }}  shape="circle"
        theme="filled_blue"
        
        onError={() => {
          console.log('Login Failed');
        }}
        isSignedIn={true}
       
      />
       
        </GoogleOAuthProvider>
    )
}
export default Login;