import React from "react";

//About Component
const About = () => {
  return (
    <div className="app">
      <h1>About Page Body</h1>
    </div>
  );
};

export default About;