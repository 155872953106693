import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isSignedIn: false,
    usernamedata: null,
    useremailData: null,
    searchInput: "tech",
    blogData: null,
  },
  reducers: {
    setSignedIn: (state, action) => {
      state.isSignedIn = action.payload;
    },
    setUserName: (state, action) => {
      state.usernamedata = action.payload;
    },
    setUserEmail: (state, action) => {
      state.useremailData = action.payload;
    },
  },
});

export const {
  setSignedIn,
  setUserName,
  setUserEmail,
} = userSlice.actions;

export const selectSignedIn = (state) => state.user.isSignedIn;
export const selectUserNameData = (state) => state.user.usernamedata;
export const selectUserEmailData = (state) => state.user.useremailData;


export default userSlice.reducer;