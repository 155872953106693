//import logo from './logo.svg';
//import Headertop from '../component/Headertop';
import React from 'react';
//import { useState } from "react";
//import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
	
	selectUserNameData,
	selectUserEmailData
   
  } from "../features/userslicer"; 

const Candiprofile=()=> {
	const userNameData = useSelector(selectUserNameData);
    const userEmailData = useSelector(selectUserEmailData);

	
  return (
    <div className="main-body"><br/>
			<div className="row">
				<div className="col-lg-4">
					<div className="card">
						<div className="card-body">
							<div className="d-flex flex-column align-items-center text-center">
								<img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="Admin" className="rounded-circle p-1 bg-primary" width="110"/>
								<div className="mt-3">
									<h4>{userNameData}</h4>
									<p className="text-secondary mb-1">Full Stack Developer</p>
									<p className="text-muted font-size-sm">{userNameData}</p>
									<button className="btn btn-primary">Follow</button>
									<button className="btn btn-outline-primary">Message</button>
								</div>
							</div>
							
							
						</div>
					</div>
				</div><br/>
				<div className="col-lg-8">
					<div className="card">
						<div className="card-body">
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Full Name</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value={userNameData}/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Email</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value={userEmailData}/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Phone</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value=""/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Mobile</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value=""/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Address</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value=""/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-3"></div>
								<div className="col-sm-9 text-secondary">
									<input type="button" className="btn btn-primary px-4" value="Save Changes"/>
								</div>
							</div>
						</div>
					</div>
			
				</div>
			</div><br/>
		</div>
  );
}
export default Candiprofile;